@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.unlisted-members {
  min-height: 100dvh;
  background-color: light.$surface-primary-default;
  padding-bottom: spacing.$l;

  &__header {
    padding: spacing.$m 0;
    border-bottom: 1px solid light.$ge-divider-default;
  }

  &__header-content {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
  }

  &__back {
    display: flex;
  }

  &__result {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-between;
    gap: spacing.$m;
  }

  &__broaden-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: spacing.$xs;
    border-radius: corner-radius.$s;
    padding: spacing.$m;
    background-color: light.$surface-secondary-default;
    margin-top: spacing.$m;
  }

  &__broaden-search-text {
    max-width: 650px;
  }
}
